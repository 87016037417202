<template>
  <div id="main-page">
    <div class="container">
      <app-bar
        :title="'Pemeriksaan'"
        :previousLink="{
          name: 'Fpp',
        }"
      />

      <loading v-if="(loading.fppDetails || loading.fpp) && !fppDetail && !fppActive"/>
      <empty v-else-if="!fppDetail || !fppActive" :title="'TIdak ada hasil ditemukan'" :message="'Maaf layanan yang anda cari tidak ditemukan, silahkan coba layanan lainnya.'"/>
      <div v-else class="row">
        <div class="col-md-3 mb-4 mb-md-0">
          <div class="content-group">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <img class="product-img mb-3" :src="fppActive.icon">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <span class="fw-700 fs-18 color-neutral-900 mb-1">
                  {{ fppDetail.nama_group_fpp }}
                </span>
                <span class="fw-400 fs-14 color-neutral-600">
                  {{ fppDetail.nama }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 ">
          <div class="desc-group">
            <p class="fw-700 fs-14 color-neutral-900 mb-1">
              Deskripsi
            </p>
            <p class="fw-400 fs-12 color-neutral-600 text-justify">
              <span class="fw-600">Harga:</span> Rp. {{ parseFloat(fppDetail.harga).toLocaleString('id-ID') }} <br>
              <span class="fw-600">Syarat Periksa:</span> {{ fppDetail.syarat_periksa }} <br>
              <span class="fw-600">Jadwal Pengerjaan:</span> {{ fppDetail.jadwal_pengerjaan }} <br>
              <span class="fw-600">Selesai Hasil:</span> {{ fppDetail.selesai_hasil }}
            </p>
          </div>

          <div class="patient-review">
            <div class="row">
              <div class="d-flex flex-column">
                <div class="d-none d-md-block pt-2">
                  <button class="btn text-white w-100 pt-2 pb-2"
                    :class="{ 'btn-orange': itemIndex < 0, 'btn-disable':itemIndex > -1}"
                    @click="selectFpp(fppDetail)">
                    {{ (itemIndex > -1) ? 'Hapus Dari Keranjang':'Tambahkan' }}
                  </button>
                  <!-- <button class="btn btn-buy bg-warning py-3 text-white">
                    Tambahkan
                  </button> -->
                </div>
                <div class="d-md-none bottom-bar pb-md-3 p-3 px-md-0">
                  <!-- Notifikasi ditambahkan ke keranjang -->
                  <div v-if="displayNotif" class="row">
                    <div class="col">
                      <div class="notification bg-info-50 d-flex align-items-center gap-3">
                        <div class="icon-wrapper">
                          <img
                            src="@/assets/icon/topup-wallet.svg"
                            alt=""
                          >
                        </div>
                        <span class="fw-400 fs-12 color-info-500">
                          {{ messageNotif }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <button class="btn text-white w-100 pt-2 pb-2"
                        :class="{ 'btn-orange': itemIndex < 0, 'btn-disable':itemIndex > -1}"
                        @click="selectFpp(fppDetail)">
                        {{ (itemIndex > -1) ? 'Hapus Dari Keranjang':'Tambahkan' }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, nextTick, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import AppBar from '@/components/AppBar.vue'
import Loading from '@/components/utility/Loading.vue'
import Empty from '@/components/utility/Empty.vue'
import { fppDetail, getFppDetail, fpps, getFpps, loading } from '@/core/fpp'
import { title } from '@/core/page'
import { addToCart, cart, getCart, removeFromCart } from '@/core/cart'
import { showNotif } from '@/core/utility'

export default {
  components: {
    AppBar, Loading, Empty
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const fppActive = ref(null)
    const displayNotif = ref(false)
    const messageNotif = ref('')
    const itemIndex = computed(() => {
      return cart.value.arr_paket.findIndex(_item => _item.id_dt === fppDetail.value.id_dt)
    })

    const selectFpp = async (item) => {
      // cek sudah login belum ?
      // toggle pesanan
      const namaGroup = item.nama_group_fpp ?? fppDetail.value.nama_group_fpp
      if (itemIndex.value > -1) {
        await removeFromCart(cart.value.arr_paket[itemIndex.value])
        messageNotif.value = 'Berhasil dihapus dari keranjang'
      } else {
        const result = await addToCart({
          id_dt: item.id_dt,
          kode: item.kode,
          nama: item.nama,
          harga: item.harga,
          group: namaGroup
        })

        let message = result.message
        if (message === 'login') {
          message = 'Silahkan login terlebih dahulu'
          router.push({
            name: 'Login'
          })

          // Trigger toast
          showNotif({
            status: 'error',
            message: message
          })
        }
        messageNotif.value = 'Berhasil ditambahkan ke keranjang'
      }
      displayNotif.value = true
      await getCart()
    }

    onMounted(async () => {
      await getCart()
      await getFppDetail(route.params.kode)

      if (!fpps.value.length) {
        await getFpps()
      }
      fppActive.value = fpps.value.find(_fpp => parseInt(_fpp.id) === parseInt(route.params.id)) || fpps.value[0]

      nextTick(() => {
        title.value = fppDetail.value.nama || 'Tes Pemeriksaan'
      })
    })

    return {
      loading,
      fppDetail,
      fppActive,
      displayNotif,
      selectFpp,
      itemIndex,
      messageNotif
    }
  }
}
</script>

<style lang="scss" scoped>
.product-img {
  width: 10rem;
  height: auto;

  @media screen and (max-width: 540px) {
    width: 84px;
  }
}

.btn-buy {
    width: 35%;
    border-radius: 10px !important;
}

.btn-disable {
  background: #CBD5E0;
}

.package-service {
  &-item {
    padding: 8px;
    box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;
  }
}

.bottom-bar {
  @media (max-width: 520px) {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: none;
  }

  .notification {
    padding: 10px;
    border-radius: 12px;
    margin-bottom: 24px;
  }
}

</style>
