import { reactive, ref } from 'vue'
import axios from '../plugins/axios'
import { selectedBranch } from './subDivisions'

export const fpps = ref([])
export const fppDetail = ref(null)
export const fppDetails = ref([])

export const loading = reactive({
  fpp: false,
  fppDetails: false,
  search: false
})

export const cart = ref([])

export const getFpps = async () => {
  const KEY = 'main.fpps'

  try {
    loading.fpp = true

    fpps.value = JSON.parse(localStorage.getItem(KEY)) || []
    const { data } = await axios.get('app/list_fpp', {
      params: {
        cabang: selectedBranch.value.id
      }
    })

    if (data.success) {
      fpps.value = data.data

      localStorage.setItem(KEY, JSON.stringify(data.data))
    } else {
      console.error('Error dari fpp get fpps = ', data.message)
    }
  } catch (error) {
    console.error(error)
    throw error
  } finally {
    loading.fpp = false
  }
}

export const getFppDetail = async (kode) => {
  try {
    loading.fpp = true

    const { data } = await axios.get('app/list_fpp_detail_kode', {
      params: {
        cabang: selectedBranch.value.id,
        kode: kode
      }
    })

    if (data.success) {
      fppDetail.value = data.data[0]
    } else {
      console.error('Error dari fpp get fpp detail = ', data.message)
    }
  } catch (error) {
    console.error(error)
    throw error
  } finally {
    loading.fpp = false
  }
}

export const getFppDetails = async (payload) => {
  try {
    const KEY = `fppDetails.cabang-${payload.cabang}.fpp-${payload.id_fpp}`
    loading.fppDetails = true
    fppDetails.value = JSON.parse(localStorage.getItem(KEY)) || []

    const { data } = await axios.get('app/list_fpp_detail', {
      params: payload
    })

    if (data.success) {
      fppDetails.value = data.data

      localStorage.setItem(KEY, JSON.stringify(data.data))
    } else {
      console.error('Error dari fpp get detail = ', data.message)
    }
  } catch (error) {
    console.error('Error dari fpp get detail = ', error)
    throw error
  } finally {
    loading.fppDetails = false
  }
}

export const search = async (keyword) => {
  try {
    // const KEY = `fppDetails.cabang-${selectedBranch.value.id}.fpp-${keyword.id_fpp}`
    loading.search = true
    fppDetails.value = []

    const { data } = await axios.get('app/list_fpp_detail_all', {
      params: {
        cabang: selectedBranch.value.id,
        nama: keyword
      }
    })

    if (data.success) {
      fppDetails.value = data.data
    } else {
      console.error('Error dari fpp search = ', data.message)
    }
  } catch (error) {
    console.error('Error dari fpp search = ', error)
    throw error
  } finally {
    loading.search = false
  }
}
